import React, {useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState, ForwardedRef} from 'react';
import {Pagination, Table as AntTable} from 'antd';
import {SorterResult} from "antd/es/table/interface";
import {SortOrder} from "antd/lib/table/interface";
import debounce from 'lodash/debounce';
import {Project} from "backend/services/printdown";
import {FilterFieldsConfig} from 'components/table/filter-form/types';
import {IPagination} from 'utils/types';
import {DefaultPageSize, PaginationConfig} from "../constants";
import FilterForm, {FilterValuesType} from '../filter-form';
import './styles.scss';

interface ILocalTableProps {
    columns: any[],
    className?: string;
    dataSource: any[],
    doSearch?: (params: any) => void,
    toolbar?: JSX.Element | null;
    title?: string;
    filters?: FilterFieldsConfig;
    localPagination?: boolean,
    loading: boolean,
    onChangeFilters?: (newFilters: FilterValuesType) => void;
    onChangePagination?: (newPagination: IPagination) => void;
    onChangeSort?: (newSort: any) => void;
    onTableStateChange?: (newState: any) => void;
    onSearchBtnClick?: () => void;
    onRow?: any,
    pageNum: number,
    pageSize: number,
    rowClassName?: (record: any) => string,
    rowKey?: string,
    total: number,
    size?: 'small' | 'default'
    sortBy?: string,
    sortDirection?: SortOrder
}


export const LocalTable = React.forwardRef((props: ILocalTableProps, ref) => {
    const {
        columns,
        dataSource = [],
        toolbar,
        loading = false,
        title = '',
        className,
        filters = [],
        doSearch,
        localPagination = false,
        total = 0,
        pageNum = 0,
        pageSize = DefaultPageSize,
        size = 'small',
        sortBy = '',
        sortDirection = 'ascend',
        onChangePagination,
        onChangeSort,
        onSearchBtnClick,
        onChangeFilters,
        ...tableProps
    } = props;

    const [selectedFilters, setSelectedFilters] = useState<FilterValuesType>({});

    const filterFormRef = useRef(null);

    const getFilters = () => {
        // @ts-ignore
        if (filterFormRef.current && filterFormRef.current.getSelectedFilters) {
            // @ts-ignore
            return filterFormRef.current.getSelectedFilters();
        } else {
            return {};
        }
    }

    const setFilterValues = (newFilters:any) => {
        // @ts-ignore
        if (filterFormRef.current && filterFormRef.current.setFilterValues) {
            // @ts-ignore
            filterFormRef.current.setFilterValues(newFilters);
        }
    }


    const delayedSearch = useCallback(
        debounce((params) => {
            if (doSearch) {
                doSearch(params);
            }
        }, 300),
        [doSearch]
    );

    const onPaginationChange = useCallback((page: number, pageSize: number) => {
        if (onChangePagination) {
            onChangePagination({pageNum: page - 1, pageSize});
        }

    }, [onChangePagination]);

    const onTableStateChange = useCallback((pagination: any, filters: any, sort: SorterResult<Project>) => {
        if (onChangeSort && sort.field && sort.order && sort.field !== sortBy || sort.order !== sortDirection) {
            // @ts-ignore
            onChangeSort({sortBy: sort.field, sortDirection: sort.order})
        }
    }, [sortBy, sortDirection, onChangeSort]);

    // @ts-ignore
    const filtersOptionsSumm = (filters || []).reduce((accum, fieldConfig) => accum + (fieldConfig.options || []).length, 0);

    const filterForm = useMemo(() => filters.length && doSearch ? <FilterForm
            ref={filterFormRef}
            filters={filters}
            // @ts-ignore
            onChangeFilters={(newFilters) => {
                setSelectedFilters(newFilters);

                if (onChangeFilters) {
                    onChangeFilters(newFilters);
                }
            }}
            // @ts-ignore
            onSearchBtnClick={(selectedFilters) => {
                doSearch({filters: selectedFilters});
            }}
        /> : null,
        [filters.length, filtersOptionsSumm, onChangeFilters, doSearch]);


    useImperativeHandle(ref, () => ({
        getFilters,
        setFilterValues,
    }), [getFilters,setFilterValues]);

    useEffect(() => {
        if (!onChangeFilters) {
            console.log('%c Load table data for filters', 'color: blue');
            console.log(selectedFilters);
            delayedSearch({filters: selectedFilters});
        }

    }, [JSON.stringify(selectedFilters), onChangeFilters]);


    return (
        <div
            // @ts-ignore
            className={`doc-duck-table ${className} ${loading ? 'with-loading' : ''} ${!total ? 'empty' : ''} ${!!toolbar ? 'with-toolbar' : ''} ${!!filters.length ? 'with-filters' : ''} ${tableProps.pagination === false ? 'no-pagination' : ''}`}>
            {filterForm}

            <div className={`table `}>
                {toolbar && <div className='table-toolbar'>{toolbar}</div>}
                <AntTable
                    rowKey='id'
                    size='small'
                    locale={{emptyText: 'Нет данных',}}
                    loading={loading}
                    columns={columns}
                    // pagination={false}
                    dataSource={dataSource}
                    /*@ts-ignore*/
                    onChange={onChangeSort ? onTableStateChange : null}
                    {...tableProps}
                    pagination={!onPaginationChange ? {...PaginationConfig} : {
                        ...PaginationConfig,
                        total,
                        pageSize,
                        size: 'small',
                        current: pageNum + 1,
                        onChange: onPaginationChange
                    }}

                />
            </div>
        </div>

    )
});

