/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios'; 

import {Dayjs} from "dayjs";

export interface IRequestOptions extends AxiosRequestConfig {
  /** only in axios interceptor config*/
  loading?: boolean;
  showError?: boolean;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
  /** only in axios interceptor config*/
  loading: boolean;
  showError: boolean;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = {
    loading: serviceOptions.loading,
    showError: serviceOptions.showError,
    ...options,
    method,
    url
  };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class WorkspacesService {
  /**
   *
   */
  getWorkspaceById(
    params: {
      /**  */
      workspaceId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/workspaces/{workspaceId}';
      url = url.replace('{workspaceId}', params['workspaceId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  updateWorkspace(
    params: {
      /**  */
      workspaceId: number;
      /** requestBody */
      body?: WorkspaceRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/workspaces/{workspaceId}';
      url = url.replace('{workspaceId}', params['workspaceId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  deleteWorkspace(
    params: {
      /**  */
      workspaceId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/workspaces/{workspaceId}';
      url = url.replace('{workspaceId}', params['workspaceId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getAllWorkspaces(
    params: {
      /**  */
      pageNum?: number;
      /**  */
      pageSize?: number;
      /**  */
      name?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/workspaces';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { pageNum: params['pageNum'], pageSize: params['pageSize'], name: params['name'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  createWorkspace(
    params: {
      /** requestBody */
      body?: WorkspaceRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/workspaces';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ContractorRolesService {
  /**
   *
   */
  updateContractorRole(
    params: {
      /**  */
      workspaceId: number;
      /**  */
      roleId: number;
      /** requestBody */
      body?: ContractorRoleRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/workspaces/{workspaceId}/contractor-roles/{roleId}';
      url = url.replace('{workspaceId}', params['workspaceId'] + '');
      url = url.replace('{roleId}', params['roleId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getContractorRoleByCompanyId(
    params: {
      /**  */
      workspaceId: number;
      /**  */
      pageNum?: number;
      /**  */
      pageSize?: number;
      /**  */
      name?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/workspaces/{workspaceId}/contractor-roles';
      url = url.replace('{workspaceId}', params['workspaceId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { pageNum: params['pageNum'], pageSize: params['pageSize'], name: params['name'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  createContractorRole(
    params: {
      /**  */
      workspaceId: number;
      /** requestBody */
      body?: ContractorRoleRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/workspaces/{workspaceId}/contractor-roles';
      url = url.replace('{workspaceId}', params['workspaceId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getContractorRoleById(
    params: {
      /**  */
      roleId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/contractor-roles/{roleId}';
      url = url.replace('{roleId}', params['roleId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  deleteContractorRole(
    params: {
      /**  */
      roleId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/contractor-roles/{roleId}';
      url = url.replace('{roleId}', params['roleId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class UserService {
  /**
   *
   */
  getUser(
    params: {
      /**  */
      userId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/{userId}';
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  updateUser(
    params: {
      /**  */
      userId: number;
      /** requestBody */
      body?: UserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/{userId}';
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  deleteUser(
    params: {
      /**  */
      userId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/{userId}';
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getAllUsers(
    params: {
      /**  */
      workspaceId: number;
      /**  */
      pageNum?: number;
      /**  */
      pageSize?: number;
      /**  */
      email?: string;
      /**  */
      name?: string;
      /**  */
      active?: boolean;
      /** Zero-based page index (0..N) */
      page?: number;
      /** The size of the page to be returned */
      size?: number;
      /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
      sort?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/workspaces/{workspaceId}/users';
      url = url.replace('{workspaceId}', params['workspaceId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageNum: params['pageNum'],
        pageSize: params['pageSize'],
        email: params['email'],
        name: params['name'],
        active: params['active'],
        page: params['page'],
        size: params['size'],
        sort: params['sort']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  createUser(
    params: {
      /**  */
      workspaceId: number;
      /** requestBody */
      body?: UserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/workspaces/{workspaceId}/users';
      url = url.replace('{workspaceId}', params['workspaceId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  sendInvitation(
    params: {
      /**  */
      userid: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/{userid}/invitations';
      url = url.replace('{userid}', params['userid'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getAllRoles(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/roles';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getWorkspaceByCode(
    params: {
      /**  */
      code: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/codes/{code}';
      url = url.replace('{code}', params['code'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  findUsersByEmail(
    params: {
      /**  */
      email: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/byEmail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { email: params['email'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  removeUserFromWorkspace(
    params: {
      /**  */
      workspaceId: number;
      /**  */
      userId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/workspaces/{workspaceId}/users/{userId}';
      url = url.replace('{workspaceId}', params['workspaceId'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ContractorsService {
  /**
   *
   */
  getAttachedContractorById(
    params: {
      /**  */
      projectId: number;
      /**  */
      contractorId: number;
      /**  */
      roleId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectId}/contractors/{contractorId}/roles/{roleId}';
      url = url.replace('{projectId}', params['projectId'] + '');
      url = url.replace('{contractorId}', params['contractorId'] + '');
      url = url.replace('{roleId}', params['roleId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  updateAttachedContractor(
    params: {
      /**  */
      projectId: number;
      /**  */
      contractorId: number;
      /**  */
      roleId: number;
      /** requestBody */
      body?: UpdateAttachedContractorRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectId}/contractors/{contractorId}/roles/{roleId}';
      url = url.replace('{projectId}', params['projectId'] + '');
      url = url.replace('{contractorId}', params['contractorId'] + '');
      url = url.replace('{roleId}', params['roleId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Attach the existing contractor to the project
   */
  attachContractor(
    params: {
      /**  */
      projectId: number;
      /**  */
      contractorId: number;
      /**  */
      roleId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectId}/contractors/{contractorId}/roles/{roleId}';
      url = url.replace('{projectId}', params['projectId'] + '');
      url = url.replace('{contractorId}', params['contractorId'] + '');
      url = url.replace('{roleId}', params['roleId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  removeFromProject(
    params: {
      /**  */
      projectId: number;
      /**  */
      contractorId: number;
      /**  */
      roleId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectId}/contractors/{contractorId}/roles/{roleId}';
      url = url.replace('{projectId}', params['projectId'] + '');
      url = url.replace('{contractorId}', params['contractorId'] + '');
      url = url.replace('{roleId}', params['roleId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get a free contractor (not attached to the project)
   */
  getContractorById(
    params: {
      /**  */
      contractorId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/contractors/{contractorId}';
      url = url.replace('{contractorId}', params['contractorId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Updating a free contractor (not attached to the project)
   */
  updateContractor(
    params: {
      /**  */
      contractorId: number;
      /** requestBody */
      body?: UpdateFreeContractorRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/contractors/{contractorId}';
      url = url.replace('{contractorId}', params['contractorId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete a free contractor (not attached to the project)
   */
  deleteContractor(
    params: {
      /**  */
      contractorId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/contractors/{contractorId}';
      url = url.replace('{contractorId}', params['contractorId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Add a new contractor to the project
   */
  addContractor(
    params: {
      /**  */
      projectId: number;
      /** requestBody */
      body?: AddContractorRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectId}/contractors';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all contractors in the project
   */
  findBy(
    params: {
      /**  */
      workspaceId: number;
      /**  */
      projectId: number;
      /**  */
      name?: string;
      /**  */
      type?: string;
      /** Zero-based page index (0..N) */
      page?: number;
      /** The size of the page to be returned */
      size?: number;
      /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
      sort?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/workspaces/{workspaceId}/projects/{projectId}/contractors';
      url = url.replace('{workspaceId}', params['workspaceId'] + '');
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        name: params['name'],
        type: params['type'],
        page: params['page'],
        size: params['size'],
        sort: params['sort']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all uniq contractors in the workspace
   */
  findBy1(
    params: {
      /**  */
      workspaceId: number;
      /**  */
      name?: string;
      /**  */
      type?: string;
      /**  */
      projectId?: number;
      /** Zero-based page index (0..N) */
      page?: number;
      /** The size of the page to be returned */
      size?: number;
      /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
      sort?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/workspaces/{workspaceId}/contractors';
      url = url.replace('{workspaceId}', params['workspaceId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        name: params['name'],
        type: params['type'],
        projectId: params['projectId'],
        page: params['page'],
        size: params['size'],
        sort: params['sort']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all types of contractors
   */
  getFileTypes(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/contractors/types';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class DocumentsService {
  /**
   *
   */
  updateDocument(
    params: {
      /**  */
      documentId: number;
      /** requestBody */
      body?: DocumentsRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/documents/{documentId}';
      url = url.replace('{documentId}', params['documentId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  deleteDocument(
    params: {
      /**  */
      documentId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/documents/{documentId}';
      url = url.replace('{documentId}', params['documentId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getAllDocuments2(
    params: {
      /**  */
      contractorId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/contractors/{contractorId}/documents';
      url = url.replace('{contractorId}', params['contractorId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  addDocument(
    params: {
      /**  */
      contractorId: number;
      /** requestBody */
      body?: DocumentsRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/contractors/{contractorId}/documents';
      url = url.replace('{contractorId}', params['contractorId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CompaniesService {
  /**
   *
   */
  updateCompany(
    params: {
      /**  */
      companyId: number;
      /** requestBody */
      body?: CompanyRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{companyId}';
      url = url.replace('{companyId}', params['companyId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  deleteCompany(
    params: {
      /**  */
      companyId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{companyId}';
      url = url.replace('{companyId}', params['companyId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getAllCompanies(
    params: {
      /**  */
      workspaceId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/workspaces/{workspaceId}/companies';
      url = url.replace('{workspaceId}', params['workspaceId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  createCompany(
    params: {
      /**  */
      workspaceId: number;
      /** requestBody */
      body?: CompanyRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/workspaces/{workspaceId}/companies';
      url = url.replace('{workspaceId}', params['workspaceId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getCompanyById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ProjectsService {
  /**
   *
   */
  updateProject(
    params: {
      /**  */
      companyId: number;
      /**  */
      projectId: number;
      /** requestBody */
      body?: ProjectRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{companyId}/projects/{projectId}';
      url = url.replace('{companyId}', params['companyId'] + '');
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  createProject(
    params: {
      /**  */
      workspaceId: number;
      /**  */
      companyId: number;
      /** requestBody */
      body?: ProjectRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/workspaces/{workspaceId}/companies/{companyId}/projects';
      url = url.replace('{workspaceId}', params['workspaceId'] + '');
      url = url.replace('{companyId}', params['companyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getContractorsByProjectId(
    params: {
      /**  */
      projectId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectId}/contractors';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getAllProjects(
    params: {
      /**  */
      workspaceId: number;
      /**  */
      name?: string;
      /**  */
      companyId?: number;
      /**  */
      userId?: number;
      /** Zero-based page index (0..N) */
      page?: number;
      /** The size of the page to be returned */
      size?: number;
      /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
      sort?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/workspaces/{workspaceId}/projects';
      url = url.replace('{workspaceId}', params['workspaceId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        name: params['name'],
        companyId: params['companyId'],
        userId: params['userId'],
        page: params['page'],
        size: params['size'],
        sort: params['sort']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getProjectById(
    params: {
      /**  */
      projectId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  deleteProject(
    params: {
      /**  */
      projectId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class TemplatesService {
  /**
   *
   */
  createTemplate(
    params: {
      /**  */
      workspaceId: number;
      /**  */
      contractorRoleId: number;
      /** requestBody */
      body?: RequestTemplate;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/workspaces/{workspaceId}/contractor-roles/{contractorRoleId}/templates';
      url = url.replace('{workspaceId}', params['workspaceId'] + '');
      url = url.replace('{contractorRoleId}', params['contractorRoleId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getTemplatesByContractorId(
    params: {
      /**  */
      contractorId: number;
      /**  */
      contractorRoleId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/contractor/{contractorId}/contractor-roles/{contractorRoleId}/templates';
      url = url.replace('{contractorId}', params['contractorId'] + '');
      url = url.replace('{contractorRoleId}', params['contractorRoleId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class AuthService {
  /**
   *
   */
  token(
    params: {
      /** requestBody */
      body?: LoginRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class DocumentFilesService {
  /**
   *
   */
  getFile(
    params: {
      /**  */
      documentId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/documents/{documentId}/files';
      url = url.replace('{documentId}', params['documentId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  attach(
    params: {
      /**  */
      documentId: number;
      /**  */
      type: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/documents/{documentId}/files';
      url = url.replace('{documentId}', params['documentId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { type: params['type'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  deleteFiles(
    params: {
      /**  */
      documentId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/documents/{documentId}/files';
      url = url.replace('{documentId}', params['documentId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  deleteFile(
    params: {
      /**  */
      fileId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/files/{fileId}';
      url = url.replace('{fileId}', params['fileId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class LoginsService {
  /**
   *
   */
  getAllUsers1(
    params: {
      /**  */
      name?: string;
      /** Zero-based page index (0..N) */
      page?: number;
      /** The size of the page to be returned */
      size?: number;
      /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
      sort?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/logins';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { name: params['name'], page: params['page'], size: params['size'], sort: params['sort'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class FixService {
  /**
   * Repair operations
   */
  fix(
    params: {
      /**  */
      to: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/fix';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { to: params['to'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class DocumentTypesService {
  /**
   *
   */
  getAllDocuments(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/documents/types';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class DocumentSetService {
  /**
   *
   */
  getAllDocuments1(
    params: {
      /**  */
      contractorType: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/documents/set';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { contractorType: params['contractorType'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ActuatorService {
  /**
   *
   */
  links(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/actuator';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  handle(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/actuator/health';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  handle1(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/actuator/health/**';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export interface WorkspaceRequest {
  /**  */
  name?: string;
}

export interface WorkspaceSrc {
  /**  */
  id?: number;

  /**  */
  createDate?: string;

  /**  */
  active?: boolean;

  /**  */
  name?: string;
}

export interface ContractorRoleRequest {
  /**  */
  name?: string;
}

export interface ContractorRole {
  /**  */
  id?: number;

  /**  */
  createDate?: string;

  /**  */
  active?: boolean;

  /**  */
  name?: string;

  /**  */
  workspaceId?: number;
}

export interface UserRequest {
  /**  */
  email?: string;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  role?: EnumUserRequestRole;

  /**  */
  projects?: number[];
}

export interface AvailableProjectResponse {
  /**  */
  id?: number;

  /**  */
  name?: string;
}

export interface AvailableWorkspaceResponse {
  /**  */
  id?: number;

  /**  */
  name?: string;
}

export interface RoleResponse {
  /**  */
  roleCode?: string;

  /**  */
  roleName?: string;

  /**  */
  description?: string;
}

export interface UserResponse {
  /**  */
  id?: number;

  /**  */
  createDate?: string;

  /**  */
  active?: boolean;

  /**  */
  email?: string;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  workspaces?: WorkspaceResponse[];

  /**  */
  role?: RoleResponse;

  /**  */
  projects?: AvailableProjectResponse[];

  /**  */
  code?: string;
}

export interface UpdateAttachedContractorRequest {
  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  telegram?: string;

  /**  */
  email?: string;

  /**  */
  phone?: string;

  /**  */
  newRoleId?: number;
}

export interface ContractorResponse {
  /**  */
  id?: number;

  /**  */
  createDate?: string;

  /**  */
  active?: boolean;

  /**  */
  type?: EnumContractorType;

  /**  */
  typeName?: string;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  telegram?: string;

  /**  */
  email?: string;

  /**  */
  phone?: string;

  /**  */
  workspaceId?: number;

  /**  */
  userId?: number;

  /**  */
  userName?: string;

  /**  */
  projectAndRoles?: ProjectAndRole[]; 

roleId?: number, 

roleName?: string

  /**  */
  documents?: DocumentResponseShort[];
}

export interface DocumentResponseShort {
  /**  */
  documentId?: number;

  /**  */
  contractorId?: number;

  /**  */
  createDate?: string;

  /**  */
  active?: boolean;

  /**  */
  documentTypeId?: number;

  /**  */
  documentTypeName?: string;

  /**  */
  documentTypeCode?: string;
}

export interface ProjectAndRole {
  /**  */
  projectId?: number;

  /**  */
  roleId?: number;

  /**  */
  projectName?: string;

  /**  */
  roleName?: string;
}

export interface BankDetailsRequest {
  /**  */
  recipientName?: string;

  /**  */
  accountNumber?: string;

  /**  */
  bic?: string;

  /**  */
  recipientBank?: string;

  /**  */
  correspondentAccount?: string;

  /**  */
  inn?: string;

  /**  */
  kpp?: string;
}

export interface BirthCertificateRequest {
  /**  */
  serial?: string;

  /**  */
  number?: string;

  /**  */
  issue?: string;

  /**  */
  issueDate?: string;
}

export interface DocumentsRequest {
  /**  */
  documentTypeId?: number;

  /**  */
  passportRequest?: PassportRequest;

  /**  */
  innRequest?: InnRequest;

  /**  */
  snilsRequest?: SnilsRequest;

  /**  */
  npdRequest?: NpdRequest;

  /**  */
  ogrnipRequest?: OgrnipRequest;

  /**  */
  usnRequest?: UsnRequest;

  /**  */
  birthCertificateRequest?: BirthCertificateRequest;

  /**  */
  passportChildRequest?: PassportChildRequest;

  /**  */
  bankDetailsRequest?: BankDetailsRequest;
}

export interface InnRequest {
  /**  */
  number?: string;
}

export interface NpdRequest {}

export interface OgrnipRequest {
  /**  */
  number?: string;
}

export interface PassportChildRequest {
  /**  */
  number?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  middleName?: string;

  /**  */
  issue?: string;

  /**  */
  issueDate?: string;

  /**  */
  unitCode?: string;

  /**  */
  sex?: string;

  /**  */
  birthDate?: string;

  /**  */
  birthPlace?: string;

  /**  */
  address?: string;
}

export interface PassportRequest {
  /**  */
  number?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  middleName?: string;

  /**  */
  issue?: string;

  /**  */
  issueDate?: string;

  /**  */
  unitCode?: string;

  /**  */
  sex?: string;

  /**  */
  birthDate?: string;

  /**  */
  birthPlace?: string;

  /**  */
  address?: string;
}

export interface SnilsRequest {
  /**  */
  number?: string;
}

export interface UsnRequest {}

export interface Document {
  /**  */
  documentId?: number;

  /**  */
  createDate?: string;

  /**  */
  active?: boolean;

  /**  */
  documentTypeId?: number;

  /**  */
  contractorId?: number;
}

export interface UpdateFreeContractorRequest {
  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  telegram?: string;

  /**  */
  email?: string;

  /**  */
  phone?: string;
}

export interface CompanyRequest {
  /**  */
  name?: string;

  /**  */
  address?: string;

  /**  */
  inn?: string;

  /**  */
  kpp?: string;

  /**  */
  ogrn?: string;

  /**  */
  bankAccount?: string;

  /**  */
  bankName?: string;

  /**  */
  bankBik?: string;

  /**  */
  bankCorrespondentAccount?: string;

  /**  */
  phone?: string;

  /**  */
  email?: string;

  /**  */
  owner?: string;
}

export interface Company {
  /**  */
  id?: number;

  /**  */
  createDate?: string;

  /**  */
  active?: boolean;

  /**  */
  name?: string;

  /**  */
  address?: string;

  /**  */
  inn?: string;

  /**  */
  kpp?: string;

  /**  */
  ogrn?: string;

  /**  */
  bankAccount?: string;

  /**  */
  bankName?: string;

  /**  */
  bankBik?: string;

  /**  */
  bankCorrespondentAccount?: string;

  /**  */
  phone?: string;

  /**  */
  email?: string;

  /**  */
  owner?: string;

  /**  */
  workspaceId?: number;
}

export interface ProjectRequest {
  /**  */
  name?: string;

  /**  */
  userId?: number;
}

export interface Project {
  /**  */
  id?: number;

  /**  */
  createDate?: string;

  /**  */
  active?: boolean;

  /**  */
  name?: string;

  /**  */
  workspaceId?: number;

  /**  */
  companyId?: number;

  /**  */
  userId?: number;
}

export interface RequestTemplate {
  /**  */
  body?: string;
}

export interface AddContractorRequest {
  /**  */
  type?: EnumContractorType;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  telegram?: string;

  /**  */
  email?: string;

  /**  */
  phone?: string;

  /**  */
  roleId?: number;

  /**  */
  userId?: number;
}

export interface LoginRequest {
  /**  */
  email?: string;

  /**  */
  password?: string;
}

export interface TokenResponse {
  /**  */
  access_token?: string;

  /**  */
  token_type?: string;

  /**  */
  expires_in?: number;

  /**  */
  userId?: number;

  /**  */
  workspaces?: number[];
}

export interface File {
  /**  */
  fileId?: number;

  /**  */
  createDate?: string;

  /**  */
  documentId?: number;

  /**  */
  documentTypeCode?: string;

  /**  */
  contentType?: string;

  /**  */
  filename?: string;

  /**  */
  workspaceId?: number;

  /**  */
  contractorId?: number;

  /**  */
  active?: boolean;

  /**  */
  s3ObjectKey?: string;

  /**  */
  s3ObjectKeyOld?: string;
}

export interface PageWorkspace {
  /**  */
  totalPages?: number;

  /**  */
  totalElements?: number;

  /**  */
  pageable?: PageableObject;

  /**  */
  numberOfElements?: number;

  /**  */
  size?: number;

  /**  */
  content?: Workspace[];

  /**  */
  number?: number;

  /**  */
  sort?: SortObject;

  /**  */
  first?: boolean;

  /**  */
  last?: boolean;

  /**  */
  empty?: boolean;
}

export interface PageableObject {
  /**  */
  paged?: boolean;

  /**  */
  unpaged?: boolean;

  /**  */
  pageNumber?: number;

  /**  */
  pageSize?: number;

  /**  */
  offset?: number;

  /**  */
  sort?: SortObject;
}

export interface SortObject {
  /**  */
  sorted?: boolean;

  /**  */
  unsorted?: boolean;

  /**  */
  empty?: boolean;
}

export interface PageUserResponse {
  /**  */
  totalPages?: number;

  /**  */
  totalElements?: number;

  /**  */
  pageable?: PageableObject;

  /**  */
  numberOfElements?: number;

  /**  */
  size?: number;

  /**  */
  content?: UserResponse[];

  /**  */
  number?: number;

  /**  */
  sort?: SortObject;

  /**  */
  first?: boolean;

  /**  */
  last?: boolean;

  /**  */
  empty?: boolean;
}

export interface PageProjectResponse {
  /**  */
  totalPages?: number;

  /**  */
  totalElements?: number;

  /**  */
  pageable?: PageableObject;

  /**  */
  numberOfElements?: number;

  /**  */
  size?: number;

  /**  */
  content?: ProjectResponse[];

  /**  */
  number?: number;

  /**  */
  sort?: SortObject;

  /**  */
  first?: boolean;

  /**  */
  last?: boolean;

  /**  */
  empty?: boolean;
}

export interface ProjectResponse {
  /**  */
  id?: number;

  /**  */
  createDate?: string;

  /**  */
  active?: boolean;

  /**  */
  name?: string;

  /**  */
  logoLetter?: string;

  /**  */
  logoColor?: string;

  /**  */
  companyId?: number;

  /**  */
  companyName?: string;

  /**  */
  numberOfContractor?: number;

  /**  */
  userId?: number;

  /**  */
  workspaceId?: number;
}

export interface PageContractorResponse {
  /**  */
  totalPages?: number;

  /**  */
  totalElements?: number;

  /**  */
  pageable?: PageableObject;

  /**  */
  numberOfElements?: number;

  /**  */
  size?: number;

  /**  */
  content?: ContractorResponse[];

  /**  */
  number?: number;

  /**  */
  sort?: SortObject;

  /**  */
  first?: boolean;

  /**  */
  last?: boolean;

  /**  */
  empty?: boolean;
}

export interface PageContractorRole {
  /**  */
  totalPages?: number;

  /**  */
  totalElements?: number;

  /**  */
  pageable?: PageableObject;

  /**  */
  numberOfElements?: number;

  /**  */
  size?: number;

  /**  */
  content?: ContractorRole[];

  /**  */
  number?: number;

  /**  */
  sort?: SortObject;

  /**  */
  first?: boolean;

  /**  */
  last?: boolean;

  /**  */
  empty?: boolean;
}

export interface LoginResponse {
  /**  */
  id?: number;

  /**  */
  createDate?: string;

  /**  */
  status?: EnumLoginResponseStatus;

  /**  */
  login?: string;

  /**  */
  description?: string;

  /**  */
  userId?: number;

  /**  */
  userName?: string;

  /**  */
  workspaces?: number[];
}

export interface PageLoginResponse {
  /**  */
  totalPages?: number;

  /**  */
  totalElements?: number;

  /**  */
  pageable?: PageableObject;

  /**  */
  numberOfElements?: number;

  /**  */
  size?: number;

  /**  */
  content?: LoginResponse[];

  /**  */
  number?: number;

  /**  */
  sort?: SortObject;

  /**  */
  first?: boolean;

  /**  */
  last?: boolean;

  /**  */
  empty?: boolean;
}

export interface FixResult {
  /**  */
  success?: boolean;

  /**  */
  message?: string;
}

export interface FileResponse {
  /**  */
  fileId?: number;

  /**  */
  createDate?: string;

  /**  */
  documentId?: number;

  /**  */
  contentType?: string;

  /**  */
  filename?: string;

  /**  */
  s3ObjectKey?: string;

  /**  */
  content?: string;

  /**  */
  active?: boolean;
}

export interface DocumentType {
  /**  */
  id?: number;

  /**  */
  createDate?: string;

  /**  */
  active?: boolean;

  /**  */
  code?: string;

  /**  */
  name?: string;

  /**  */
  description?: string;
}

export interface DocumentSet {
  /**  */
  id?: number;

  /**  */
  createDate?: string;

  /**  */
  active?: boolean;

  /**  */
  contractorType?: EnumDocumentSetContractorType;

  /**  */
  documentTypeId?: number;

  /**  */
  required?: boolean;
}

export interface ContractorTypeResponse {
  /**  */
  name?: string;

  /**  */
  description?: string;
}

export interface Link {
  /**  */
  href?: string;

  /**  */
  templated?: boolean;
}
export enum EnumUserRequestRole {
  'SUPER_ADMIN' = 'SUPER_ADMIN',
  'ADMIN' = 'ADMIN',
  'USER' = 'USER'
}
export enum EnumContractorType {
  'INDIVIDUAL' = 'INDIVIDUAL',
  'SELF_EMPLOYED' = 'SELF_EMPLOYED',
  'LEGAL' = 'LEGAL',
  'PHYSICAL' = 'PHYSICAL',
  'PHYSICAL_CHILD_UNDER_14' = 'PHYSICAL_CHILD_UNDER_14',
  'PHYSICAL_CHILD_OVER_14' = 'PHYSICAL_CHILD_OVER_14'
}
export enum EnumAddContractorRequestType {
  'INDIVIDUAL' = 'INDIVIDUAL',
  'SELF_EMPLOYED' = 'SELF_EMPLOYED',
  'LEGAL' = 'LEGAL',
  'PHYSICAL' = 'PHYSICAL',
  'PHYSICAL_CHILD_UNDER_14' = 'PHYSICAL_CHILD_UNDER_14',
  'PHYSICAL_CHILD_OVER_14' = 'PHYSICAL_CHILD_OVER_14'
}
export enum EnumLoginResponseStatus {
  'SUCCESS' = 'SUCCESS',
  'DECLINE' = 'DECLINE'
}
export enum EnumDocumentSetContractorType {
  'INDIVIDUAL' = 'INDIVIDUAL',
  'SELF_EMPLOYED' = 'SELF_EMPLOYED',
  'LEGAL' = 'LEGAL',
  'PHYSICAL' = 'PHYSICAL',
  'PHYSICAL_CHILD_UNDER_14' = 'PHYSICAL_CHILD_UNDER_14',
  'PHYSICAL_CHILD_OVER_14' = 'PHYSICAL_CHILD_OVER_14'
}

export interface DocumentResponse extends DocumentResponseShort, PassportRequest, InnRequest, SnilsRequest {
documentId?: number
}

export interface PassportFormValues extends Omit<PassportRequest, 'birthDate' | 'issueDate' > {
    birthDate: Dayjs | null,
    issueDate: Dayjs | null,
}


export interface Workspace {
  /**  */
  id: number;

  /**  */
  createDate?: string;

  /**  */
  active?: boolean;

  /**  */
  name: string;
  
  /**  */
  color?: string;
}

export interface WorkspaceResponse {
  /**  */
  id: number;

  /**  */
  name: string;
  
    /**  */
  color?: string;
}

export const CompanyService = new CompaniesService();
export const ContractorService = new ContractorsService();
export const ProjectService = new ProjectsService();
export const ContractorRoleService = new ContractorRolesService();
export const WorkspaceService = new WorkspacesService();
export const DocumentService = new DocumentsService();
export const DocumentsSetService = new DocumentSetService();
export const FileService = new DocumentFilesService();
export const AuthorizationService = new AuthService();
export const UsersService = new UserService();
export const LoginService = new LoginsService();
	