import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import { useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {PlusOutlined} from '@ant-design/icons';
import {Button} from 'antd';

import printdownBackend from "backend/axios";
import {Project, ProjectService} from 'backend/services/printdown';

import Loading from "components/loading";
import {PaginationConfig} from "components/table/constants";
import {RemoteTable} from "components/table/remote-table";
import {useLoading} from 'hooks/use-loading';
import {StoreState} from 'store';
import {getUserPermissions, getWorkspaceId} from "store/reducers/auth/selectors";
import {sortProjectsBy} from "store/reducers/projects";
import {showError, showMessage} from 'utils/notifications';
import {downloadFileFromResponse} from "utils/utils";
import {projectColumns} from 'views/projects/list/columns';
import {showProjectEditModal} from './project-modal';
import './styles.scss';


export const ProjectsList = () => {
    const tableRef = useRef(null);
    const [loading, showLoading, hideLoading] = useLoading();
    const {isAdmin, isSuperAdmin} = useSelector(getUserPermissions);
    const {companyId} = useSelector((state: StoreState) => state.auth);
    const workspaceId = useSelector(getWorkspaceId);

    const navigate = useNavigate();

    const reloadTable = useCallback(() => {
        // @ts-ignore
        tableRef.current?.reloadTable();
    }, [tableRef.current]);


    const onProjectChange = useCallback(() => {
        // @ts-ignore
        reloadTable();
    }, [reloadTable]);

    const onAddClick = useCallback(() => {
        showProjectEditModal({
            companyId: companyId || 0,
            onFinish: onProjectChange
        });
    }, [onProjectChange, companyId]);

    const onEditClick = useCallback((record: Project) => {
        showProjectEditModal({
            project: record,
            onFinish: onProjectChange
        });
    }, [onProjectChange, companyId]);

    const onDeleteClick = useCallback((record: Project) => {
        showLoading();
        ProjectService.deleteProject({
            projectId: record.id || 0
        })
            .then(() => {
                hideLoading();
                showMessage('Проект удалён');
                onProjectChange();
            })
            .catch(e => {
                showError('Не удалось удалить организацию', e);
                hideLoading();
            })
    }, [companyId, onProjectChange]);

    const onDownloadClick = useCallback((record: Project) => {
        showLoading();
        printdownBackend.get(`/projects/${record.id}/contractors`, {
            responseType: 'blob'
        })
            .then((response) => {
                downloadFileFromResponse(response, hideLoading);
            })
            .catch(e => {
                showError('Не удалось сохранить файл с подрядчиками', e);
                hideLoading();
            })
    }, [companyId, onProjectChange]);

    const onRow = useCallback((record: Project) => ({
        onClick: () => {
            navigate(`/projects/${record.id}`, {
                state: {projectName: record.name, openedFrom: '/projects'}
            })
        }
    }), []);

    const tableColumns = useMemo(() => projectColumns({
        showButtons: isAdmin || isSuperAdmin,
        onEditClick,
        onDeleteClick,
        onDownloadClick
    }), [onEditClick, onDeleteClick, isAdmin, isSuperAdmin, onDownloadClick]);

    useEffect(() => {
        // @ts-ignore
        tableRef.current?.setFilterValues({});
        // @ts-ignore
        tableRef.current?.reloadTable();
    }, [workspaceId]);

    return (
        <div className={`projects-list`}>
            {loading && <Loading/>}
            <RemoteTable
                ref={tableRef}
                columns={tableColumns}
                onRow={onRow}
                loadDataFn={ProjectService.getAllProjects}
                filters={[{
                    name: 'name',
                    title: 'ФИО '
                }]}
                requestParamsConverter={({pageNum, pageSize, ...params}) => ({
                    ...params,
                    page: pageNum,
                    size: pageSize,
                    workspaceId
                })}
                responseDataConverter={({content = [], totalElements = 0}) => ({
                    data: sortProjectsBy(content, {sortBy: 'createDate', sortDirection: 'descend'}),
                    total: totalElements
                })}
                toolbar={<Button
                    type='link'
                    onClick={onAddClick}
                > <PlusOutlined/>Добавить проект</Button>}
                pagination={{...PaginationConfig}}

            />

        </div>
    );
}
